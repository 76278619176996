// extracted by mini-css-extract-plugin
export var workshopPageCard = "workshop-page-module--workshop-page-card--d9825";
export var workshopPageCardDetail = "workshop-page-module--workshop-page-card-detail--91c55";
export var workshopPageCardImage = "workshop-page-module--workshop-page-card-image--a8f45";
export var workshopPageCardLink = "workshop-page-module--workshop-page-card-link--c8f9c";
export var workshopPageCardText = "workshop-page-module--workshop-page-card-text--df737";
export var workshopPageCardTitle = "workshop-page-module--workshop-page-card-title--d779c";
export var workshopPageContainer = "workshop-page-module--workshop-page-container--311b9";
export var workshopPageDescription = "workshop-page-module--workshop-page-description--e1eb9";
export var workshopPageDetail = "workshop-page-module--workshop-page-detail--bb41c";
export var workshopPageDetailImage = "workshop-page-module--workshop-page-detail-image--712e9";
export var workshopPageDetailImageWrapper = "workshop-page-module--workshop-page-detail-image-wrapper--9031c";
export var workshopPageGrid = "workshop-page-module--workshop-page-grid--33dfb";
export var workshopPageHr = "workshop-page-module--workshop-page-hr--862ab";
export var workshopPageTitle = "workshop-page-module--workshop-page-title--ba58b";