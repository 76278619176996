import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/layout'
import * as styles from '../../styles/workshop-page.module.css'

const WorkshopPage = () => {
  return (
    <Layout
      isHome={false}
      title="Mondのワークショップ"
      description="Mondでは様々な分野のワークショップを開催中。入会金などは無く、単発で参加できるのでお気軽に体験していただけます。店内でドリンクを飲みながら、お楽しみくださいませ。"
    >
      <div className={styles.workshopPageContainer}>
        <h1 className={styles.workshopPageTitle}>Workshop</h1>
        <p className={styles.workshopPageDescription}>
          Mondでは様々な分野のワークショップを開催中。<br/>
          入会金などは無く、単発で参加できるのでお気軽に体験していただけます。<br/>
          店内でドリンクを飲みながら、お楽しみくださいませ。
        </p>
        <hr className={styles.workshopPageHr}/>
        <div className={styles.workshopPageGrid}>
          <div className={styles.workshopPageCard}>
            <Link to="/workshop/mbira">
              <StaticImage
                className={styles.workshopPageCardImage}
                alt="Workshop - ジンバブエ楽器「ンビラ」"
                src="../../assets/images/thumbnail-mbira.jpg"
              />
            </Link>
            <Link to="/workshop/mbira" className={styles.workshopPageCardLink}>
              <h2 className={styles.workshopPageCardTitle}>ジンバブエ楽器「ンビラ」</h2>
            </Link>
            <p className={styles.workshopPageCardText}>
              ・毎月開催<br/>
              ・料金 1,000円 + ワンドリンクオーダー<br/>
            </p>
            <p className={styles.workshopPageCardDetail}>
              鉄の棒と木の板から作られたジンバブエの伝統楽器「ンビラ」の弾き方を学ぶワークショップを毎月開催しています。
            </p>
          </div>
          <div className={styles.workshopPageCard}>
            <Link to="/workshop/rose-window">
              <StaticImage
                className={styles.workshopPageCardImage}
                alt="Workshop - ローズウィンドウ"
                src="../../assets/images/thumbnail-rosewindow.jpg"
              />
            </Link>
            <Link to="/workshop/rose-window" className={styles.workshopPageCardLink}>
              <h2 className={styles.workshopPageCardTitle}>ローズウインドウ</h2>
            </Link>
            <p className={styles.workshopPageCardText}>
              ・毎月開催<br/>
              ・料金 3,000円 + ワンドリンクオーダー
            </p>
            <p className={styles.workshopPageCardDetail}>
              ヨーロッパの大聖堂に見られる丸いステンドグラス「バラ窓」を模したペーパークラフト「ローズウィンドウ」のワークショップを毎月開催しています。
            </p>
          </div>
          <div className={styles.workshopPageCard}>
            <StaticImage
              className={styles.workshopPageCardImage}
              alt="Workshop - ステンドグラス"
              src="../../assets/images/thumbnail-stained-glass.jpg"
            />
            <h2 className={styles.workshopPageCardTitle}>ステンドグラス</h2>
            <p className={styles.workshopPageCardText}>
              ・不定期開催<br/>
              ・料金 4,500円 + ワンドリンクオーダー
            </p>
            <p className={styles.workshopPageCardDetail}>
              たくさんの種類の色ガラス片の中からお好みの色や形のガラスを選んで、鉛を使ってつなぎ合わせてステンドグラスを作ります。
            </p>
          </div>
          <div className={styles.workshopPageCard}>
            <StaticImage
              className={styles.workshopPageCardImage}
              alt="Workshop - 真鍮アクセサリー"
              src="../../assets/images/thumbnail-brass.jpg"
            />
            <h2 className={styles.workshopPageCardTitle}>真鍮アクセサリー</h2>
            <p className={styles.workshopPageCardText}>
              ・不定期開催<br/>
              ・料金 3,000円 + ワンドリンクオーダー
            </p>
            <p className={styles.workshopPageCardDetail}>
              真鍮を叩いて、刻印や模様を入れ、世界で一つだけのオリジナルのアクセサリーを作ることができます。
            </p>
          </div>
        </div>
        <hr className={styles.workshopPageHr}/>
        <p className={styles.workshopPageDescription}>
          ワークショップはMond店内にて行なっています。<br/>
          やってみたいけどどんな感じか不安という方は、<br/>
          ワークショップの時間にご来店していただければ雰囲気が分かるかと思います。<br/>
          初めての方もリピーターさんも和気あいあいとモノづくりなどを楽しまれています。
        </p>
        <div className={styles.workshopPageDetail}>
          <div className={styles.workshopPageDetailImageWrapper}>
            <StaticImage
              className={styles.workshopPageDetailImage}
              alt="Mondのワークショップ"
              src="../../assets/images/workshop-1.jpg"
            />
          </div>
          <div className={styles.workshopPageDetailImageWrapper}>
            <StaticImage
              className={styles.workshopPageDetailImage}
              alt="Mondのワークショップ"
              src="../../assets/images/workshop-2.jpg"
            />
          </div>
        </div>
        <hr className={styles.workshopPageHr} style={{margin: "0"}}/>
      </div>
    </Layout>
  )
}

export default WorkshopPage